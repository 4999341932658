/// <reference path="../../../typings/jquery/jquery.d.ts"/>
/// <reference path="../../../typings/materialize-css/materialize-css.d.ts"/>

class DependentModal {
    private form;
    private dependent;
    private dependentId;
    private modal;
    private callback: Function;
    private lvDependent: LifeVineDependents;

    constructor(lvDependent: LifeVineDependents, dependentId, callback: Function) {
        this.lvDependent = lvDependent;
        this.dependentId = dependentId;
        this.callback = callback;
        this.modal = jQuery('#dependent_modal').modal();
        this.form = this.modal.find('form');

        if (dependentId) {
            this.lvDependent.get(dependentId)
                .done(data => {
                    this.dependent = data;
                    this.show();
                });
        } else {
            this.dependent = {
                first_name: '',
                last_name: '',
                dob: '',
                notes: ''
            };

            this.show();
        }
    }

    show() {
        setFormData(this.form, this.dependent);
        this.modal.modal('open');
        this.modal.find('.datepicker').pickadate({
            selectMonths: true,
            selectYears: 18,
            max: new Date(),
            formatSubmit: 'yyyy-mm-dd',
            hiddenName: true
        });
        Materialize.updateTextFields();
        this.modal.find('#dependent_modal_save').off('click').click(event => this.save(event));
        this.modal.find('#dependent_modal_cancel').off('click').click(event => this.cancel(event));
    }

    save(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        let formData = getFormData(this.form);

        if (this.dependentId) {
            this.lvDependent.save(this.dependentId, formData)
                .done(data => {
                    if (data.success === true) {
                        this.modal.modal('close');
                        this.callback();
                    } else {
                        this.setErrors(data.errors);
                    }

                });
        } else {
            this.lvDependent.create(formData)
                .done(data => {
                    if (data.success === true) {
                        this.modal.modal('close');
                        this.callback();
                    } else {
                        this.setErrors(data.errors);
                    }
                });
        }
    }

    cancel(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        this.modal.modal('close');
    }

    setErrors(errors) {
        jQuery.each(errors, (field, message) => {
            this.form.find(`[for="${field}"]`).attr('data-error', message);
            this.form.find(`#${field}`).removeClass('valid').addClass('invalid');
        });
    }
}